@import "./header";
@import "./sidebar";
@import "./formSection";

.CreateManifest {
  label {
    font-size: 15px;
  }
}

.container {
  margin-top: 60px;
}
table {
  tbody {
    tr {
      &:hover {
        background-color: #52a6a8a1;
      }
    }
  }
}
