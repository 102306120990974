.Dash_card {
  /* display: flex;
    flex-direction: column; */
  flex-wrap: wrap;
  background: #ffffff;
  border-radius: 6px;
  /* overflow: hidden; */
  box-shadow: 0px 1px 1px 2px rgba(201, 198, 198, 0.2);
  margin: 20px;
  width: 100%;
  height: 13vh;
  position: relative;
}

.card-content1 {
  color: white;
  text-align: center;
  padding: 8px;
  font-size: 28px;

  /* display: inline-block; */
  justify-content: center;
}

.card-content {
  /* color: white; */
  text-align: center;
  padding: 1em;
  font-size: 28px;
  align-items: center;
  /* display: inline-block; */
  justify-content: center;
  flex-wrap: wrap;
}

.card-ti {
  /* text-align: right; */
  font-size: 19px;
  margin: -21px;
  margin-right: -16px;
}

.card-description {
  text-align: right;
  color: rgb(149, 144, 144);
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.Dash_card1 {
  max-width: 90%;
  height: 69px;
  position: absolute;
  border-radius: 12px;
  color: white;
  left: 20%;
  top: -35%;
  transform: translateX(-50%);
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 767px) {

  .Dash_card1,
  .card-content {
    flex: 0 0 calc(100% - 20px);
  }
}

/* Media query for screens smaller than 768px */
@media screen and (max-width: 767px) {
  .Dash_card1 {
    max-width: 100%;
    height: auto;
    position: static;
    border-radius: 0;
    transform: none;

    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.header_res {
  width: 100%;
  
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}



.res_section {
  display: flex;
  
}

.stylebtn {
  background-color: #C8E6C9;
  border-width: 0;
  font-family: Arial, sans-serif;
  font-size: 16px;
  margin: 5px;
  width: auto;
  /* Adjusted width to make it smaller */
  border-radius: 5px;
  padding: 3px 10px;
  font-weight: bold;
}


@media screen and (max-width: 860px) {
  .header_res {
    width: 100%;
    
    background: #ffffff;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
}

.responsive {
  display: flex;
  justify-content: space-between;
  margin-top: 0rem;
  margin-left: 1rem;
  margin-right: 1rem;
}




@media screen and (max-width: 860px) {
  .responsive {
    
    margin-top: 37px;
  }
}

@media screen and (max-width: 650px) {
  .responsive {
    
    margin-top: 46px;
  }
}


@media screen and (max-width: 600px) {
  .responsive {
    
    margin-top: 28px;
  }
}

@media screen and (max-width: 500px) {
.res_section{
  display: flex;
 width: 100%;
}
}



.search_bar {
  border: 2px solid #b6afaf;
  border-radius: 14px 0px 0px 14px;
  background: none;
  border-left: none;
  height: 28px;
  width: 143px; /* Default width */
  font-size: 12px;
  text-align: center;
}

.res_search{
background: rgb(255, 255, 255);
    height: 8%;
    top: 0;
    z-index: 1;
    position: relative;
  }

@media screen and (max-width: 860px) {
  .res_search{
    width: 100%;
  }
  .search_bar {
    width: 232px; 
}
}

@media screen and (max-width: 500px) {
  .res_search{
    width: 100%;
  }
  .search_bar {
    width: 200px; 
}
}

@media screen and (max-width: 450px) {
  .res_search{
    width: 100%;
  }
  .search_bar {
    width: 150px; 
}
}



@media screen and (max-width: 300px) {
  .res_search{
    width: 100%;
  }
  .search_bar {
    width: 100px; 
}
}
@media screen and (max-width: 250px) {
  .res_search{
    width: 100%;
  }
  .search_bar {
    width: 60px; 
}
}


@media screen and (min-width: 859px) and (max-width: 900px) {
  .search_bar {
      width: 50px; 
  }
}

@media screen and (min-width: 900px) and (max-width: 956px) {
  .search_bar {
      width: 86px; 
  }
}

