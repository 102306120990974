.FormSection-header-text {
  background-color: #e6ffef;
  border-bottom: 1.5px solid #d3d3d3;
  padding: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  h4 {
    font-size: 25px;
    font-weight: 500;
    margin: 0;
  }
  h3 {
    margin: 0;
    cursor: pointer;
    font-size: 20px;
  }
}
