$const-clr: hsl(213, 100%, 95%);
.filter-text {
  padding: 10px 0px 8px 0px;
  font-size: 15px;
  cursor: pointer;
}
.filter-sub-text {
  padding: 5px;
  font-size: 15px;
  cursor: pointer;
}
.filter-sub-text:hover {
  background-color: #d3d3d3;
}
.sub-filter {
  width: 100%;
  height: 30%;
  background: #fff;
  position: absolute;
  right: 250px;
  top: 100px;
  overflow-y: scroll;
  z-index: 1;
}
.sub-filter2 {
  width: 100%;
  height: 40%;
  background: #fff;
  position: absolute;
  right: 250px;
  top: 130px;
  overflow-y: scroll;
  z-index: 1;
}
.search-icon {
  font-size: 20px;
}
.sub-header {
  background-color: red;
}
.filter-select-icon {
  font-size: 20px;
}
.filter-clear-icon {
  font-size: 15px;
  justify-content: center;
  margin: -1px 0px 35px -5px;
}
.data_item:hover {
  background-color: $const-clr;
}
.inner-text {
  padding-top: 1px;
  font-size: 13.2px;
}
.filter-label {
  font-size: 14px;
  padding-top: 8px;
}
