.dataResult {
  position: absolute;
  // margin-top: 5px;
  width: 190px;
  max-height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.dataResult_sort {
  bottom: 2px;
  width: 100px;
  max-height: 200px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1000;
}

.dataItem {
  padding: 5px 10px;
  // margin: 10px 5px;
  cursor: pointer;
}

.dataItem:hover {
  background-color: #e6f1ff;
  // color: white;
}

.dataResult .dataItem {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  color: black;
}

.showDropdown {
  display: "flex";
  justify-content: "space-between";
  align-items: "center";
  width: "100px";
  padding: "5px 15px";
  border: "1px solid #ced4da";
  border-radius: "5px";
}
